
import { api } from '../../api';

export const authApi = api.injectEndpoints({
    endpoints: build => ({
        login: build.mutation<AuthResponse, { username: string, password: string }>({
            query: ({ username, password }) => ({
                url: '/public/auth/login',
                method: 'POST',
                body: { username, password },
            }),
        }),
        forgot: build.mutation<AuthResponse, { email:string }>({
            query: ({ email }) => ({
                url: '/public/auth/forgot',
                method: 'POST',
                body: { email },
            }),
        }),
        updateProfile: build.mutation<AuthResponse, { email: string, name: string, password: string, currentPassword: string }>({
            query: ({ email, password, name, currentPassword }) => ({
                url: '/public/auth/update',
                method: 'PUT',
                body: { email, password, name, currentPassword },
            }),
        }),
        createAccount: build.mutation<AuthResponse, { email: string, username: string, name: string, password: string, referrer_shop_id?: number }>({
            query: ({ email, username, name, password, referrer_shop_id }) => ({
                url: '/public/auth/register',
                method: 'POST',
                body: { email, username, name, password, referrer_shop_id },
            }),
        }),
        profile: build.query<AuthResponse, {}>({
            query: () => '/public/auth/profile',
        }),
    }),
    overrideExisting: false,
});

export const { useLoginMutation, useCreateAccountMutation, useUpdateProfileMutation, useLazyProfileQuery, useForgotMutation } = authApi;
