import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'earnings',
    initialState: { annual: [], monthly: [], lastCheck: 0 } as EarningType,
    reducers: {
        setAnnualEarning(state, action) {
            state.annual = action.payload;
        },
        setMonthlyEarning(state, action) {
            state.monthly = action.payload;
        },
        setAllEarnings(state, action) {
            state.annual = action.payload.annual
            state.monthly = action.payload.monthly
            state.lastCheck = action.payload.lastCheck
        }
    },
});

export type EarningType = {
    annual: Earning[]
    monthly: Earning[]
    lastCheck: number
}

export const { setAnnualEarning, setMonthlyEarning, setAllEarnings } = slice.actions;
export default slice.reducer;
