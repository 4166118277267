import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';


import { api } from '../services/api';
import auth from './auth';
import app from './app';
import favorites from './favorites';
import earnings from './earnings';
import chat from './chat';
const reducers = combineReducers({
    auth,
    app,
    favorites,
    earnings,
    chat,
    [api.reducerPath]: api.reducer,
});


const store = configureStore({
    reducer: reducers,
    middleware: getDefaultMiddleware => {
        const middlewares = getDefaultMiddleware({}).concat(api.middleware);

        // if (process.env.NODE_ENV == 'development') {
        //     const createDebugger = require('redux-flipper').default;
        //     middlewares.push(createDebugger());
        // }

        return middlewares;
    },
});

// const persistor = persistStore(store);

setupListeners(store.dispatch);
export type RootState = ReturnType<typeof store.getState>
export { store };
