import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'chat',
    initialState: { selectedShop: undefined } as ChatStateType,
    reducers: {
        setSelectedShop(state, action) {
            state.selectedShop = action.payload;
        },
    },
});

export type ChatStateType = {
    selectedShop?: Shop
}

export const { setSelectedShop } = slice.actions;
export default slice.reducer;
