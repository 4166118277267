import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'favorites',
    initialState: { favoriteShops: [], favoriteProducts: [] } as Favorite,
    reducers: {
        setFavoriteShops(state, action) {
            state.favoriteShops = action.payload;
        },
        setFavoriteProducts(state, action) {
            state.favoriteProducts = action.payload;
        },
    },
});

export type Favorite = {
    favoriteShops: number[]
    favoriteProducts: number[]
}

export const { setFavoriteProducts, setFavoriteShops } = slice.actions;
export default slice.reducer;
