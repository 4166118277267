const footerMenuList = [

    {
      id: 1,
      title: "Partners",
      diffClass: "md:col-start-7",
      list: [
        {
          id: 1,
          href: "#",
          text: "Real Estate",
        },
        {
          id: 2,
          href: "#",
          text: "Car Hire",
        },
        {
          id: 3,
          href: "#",
          text: "Travel and tour",
        },
        {
          id: 4,
          href: "#",
          text: "Hotels Booking",
        },
      ],
    },
    {
      id: 2,
      title: "Company",
      diffClass: "",
      list: [
        {
          id: 2,
          href: "/about",
          text: "About Us",
        },
        {
          id: 3,
          href: "/services",
          text: "Services",
        },
        // {
        //   id: 3,
        //   href: "/shops",
        //   text: "Shops",
        // },
        {
          id: 4,
          href: "/tos",
          text: "TOS",
        },
        {
          id: 5,
          href: "/faqs",
          text: "FAQ",
        },
      ],
    },
    {
      id: 3,
      title: "My Account",
      diffClass: "",
      list: [
        {
          id: 1,
          href: "/manage/status",
          text: "Status",
        },
        {
          id: 2,
          href: "/manage/shop",
          text: "Manage Shop",
        },
        {
          id: 3,
          href: "/manage/products",
          text: "Products",
        },
        // {
        //   id: 4,
        //   href: "#",
        //   text: "Create Item",
        // },
      ],
    },
  ];

  const socialIcons = [
    {
      id: 1,
      href: "https://www.facebook.com",
      text: "facebook",
    },
    {
      id: 2,
      href: "https://www.twitter.com",
      text: "twitter",
    },
    {
      id: 3,
      href: "https://www.discord.com",
      text: "discord",
    },
    {
      id: 4,
      href: "https://www.instagram.com",
      text: "instagram",
    },
    {
      id: 5,
      href: "https://www.tiktok.com",
      text: "tiktok",
    },
  ];

  export { footerMenuList, socialIcons };