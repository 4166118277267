import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'app',
    initialState: { info: undefined, productCategories: [], shopTypes: [] } as AppState,
    reducers: {
        setAppInfo(state, action) {
            // console.log("setting info  ", action)
            state.info = action.payload;
        },
        setProductCategories(state, action) {
            state.productCategories = action.payload
        },
        setShopTypes(state, action) {
            state.shopTypes = action.payload
        }
    },
});

export type AppState = {
    info?: AppInfo,
    productCategories: ProductCategory[],
    shopTypes: ShopType[],
}

export const { setAppInfo, setProductCategories, setShopTypes } = slice.actions;
export default slice.reducer;
