'use client'
import { useLazyProfileQuery } from "@/services/modules/public/auth";
import { clearAuth, setAuth, setProfileLoading, setToken } from "@/store/auth";
import { createContext, ReactNode, useContext, useEffect, } from "react";
import { useDispatch } from "react-redux";

import cookie from 'js-cookie'
import { COOKIE_NAME, FAVORITE_SHOP_COOKIE } from "@/constants/General";
import { setFavoriteProducts, setFavoriteShops } from "@/store/favorites";
import { getFavoriteProducts, getFavoriteShops, showError } from "@/utils/General";
import { useLazyGetAppInfoQuery } from "@/services/modules/public/app";
import { setAppInfo, setProductCategories, setShopTypes } from "@/store/app";


const defaultState = {}
const AppStateContext = createContext(defaultState)

const AppStateProvider = ({ children }: { children: ReactNode }) => {
    const dispatch = useDispatch();

    const [getProfile, { isLoading, data }] = useLazyProfileQuery()
    const [getAppInfo, { isLoading: infoLoading, data: infoData, error: infoError }] = useLazyGetAppInfoQuery()

    useEffect(() => {
        console.log("First load ...")
        const token = cookie.get(COOKIE_NAME)
        getAppInfo({})
        if (token) {
            dispatch(setToken(token))
            setTimeout(() => {
                dispatch(setProfileLoading(true))
                getProfile({})
            }, 80)
        } else {
            dispatch(setProfileLoading(false))
        }
        //Favorites
        dispatch(setFavoriteShops(getFavoriteShops()))
        dispatch(setFavoriteProducts(getFavoriteProducts()))
    }, [])

    useEffect(() => {
        if (data && data.success && data.data?.profile) {
            console.log("Setting profile")
            dispatch(setAuth(data.data))

            //Get app info right after auth, this enables us to get more info for admin
            setTimeout(() => {
                getAppInfo({})
            }, 80)
        } else if (data) {
            dispatch(clearAuth(undefined))
            dispatch(setProfileLoading(false))
        }
    }, [isLoading, data])

    useEffect(() => {
        if (infoData && infoData.success && infoData.data?.appInfo) {
            console.log("App info ", infoData.data?.appInfo)
            dispatch(setAppInfo(infoData.data.appInfo))
            dispatch(setShopTypes(infoData.data.shopTypes))
            dispatch(setProductCategories(infoData.data.categories))
        } else if (infoError) {
            showError(infoError, "Unable to load app, please refresh your page")
        }
    }, [infoLoading, infoData])

    return (
        <AppStateContext.Provider value={{}} >
            {children}
        </AppStateContext.Provider>
    )
}

const useAppState = () => useContext(AppStateContext)

export { AppStateProvider, useAppState }