
"use client"
import Link from "next/link";
import { footerMenuList, socialIcons } from "../constants/FooterData";
import { websiteName } from "@/constants/BasicData";

const Footer = () => {
    return (
        <>
            {/* <!-- Footer --> */}

            <footer className="dark:bg-jacarta-900 page-footer bg-white">
                <div className="container">
                    <div className="grid grid-cols-6 gap-x-7 gap-y-14 pt-24 pb-12 md:grid-cols-12">
                        <div className="col-span-3 md:col-span-4">
                            {/* <!-- Logo --> */}
                            <Link href="#">
                                <span className="mb-6 inline-block">
                                    <img
                                        src="/images/logo.png"
                                        className="max-h-7 dark:hidden"
                                        alt={websiteName}
                                    />
                                </span>
                            </Link>

                            <Link href="#">
                                <div className="flex items-center mb-6">
                                    <img
                                        src="/images/logo_white.png"
                                        className="hidden max-h-12 dark:block "
                                        alt="Website Name"
                                    />
                                    {/* <span className="ml-2 text-lg lg:text-2xl font-bold tracking-widest">DigitalMallEx</span> */}
                                </div>
                            </Link>
                            <p className="dark:text-jacarta-300 mb-12">
                                Facilitating Global Access To Discounted Goods and Services. Powered by
                                blockchain technology.
                            </p>

                            {/* <!-- Socials --> */}
                            <div className="flex space-x-5">
                                {socialIcons.map((item) => {
                                    const { id, href, text } = item;
                                    return (
                                        <Link href={href} key={id}>
                                            <span
                                                rel="noopener noreferrer"
                                                className="group cursor-pointer"
                                            >
                                                <svg className="icon group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white">
                                                    <use xlinkHref={`/icons.svg#icon-${text}`}></use>
                                                </svg>
                                            </span>
                                        </Link>
                                    );
                                })}
                            </div>
                        </div>

                        {footerMenuList.map((single) => (
                            <div
                                className={`col-span-full sm:col-span-3 md:col-span-2 ${single.diffClass}`}
                                key={single.id}
                            >
                                <h3 className="font-display text-jacarta-700 mb-6 text-sm dark:text-white">
                                    {single.title}
                                </h3>
                                <ul className="dark:text-jacarta-300 flex flex-col space-y-1">
                                    {single.list.map((item) => {
                                        const { id, href, text } = item;
                                        return (
                                            <li key={id}>
                                                <Link href={href}>
                                                    <span className="hover:text-accent dark:hover:text-white">
                                                        {text}
                                                    </span>
                                                </Link>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        ))}
                    </div>

                    <div className="flex flex-col items-center justify-between space-y-2 py-8 sm:flex-row sm:space-y-0">
                        <span className="dark:text-jacarta-400 text-sm">
                            <span>© {new Date().getFullYear()} {websiteName} — Made by</span>
                            <Link href="https://www.reed-tech.com">
                                <span className="hover:text-accent dark:hover:text-white">
                                    {" "}
                                    ReedTech IT
                                </span>
                            </Link>
                        </span>

                        <ul className="dark:text-jacarta-400 flex flex-wrap space-x-4 text-sm">
                            <li>
                                <Link href="/tos">
                                    <span className="hover:text-accent dark:hover:text-white">
                                        Terms and conditions
                                    </span>
                                </Link>
                            </li>
                            <li>
                                <Link href="/tos">
                                    <span className="hover:text-accent dark:hover:text-white">
                                        Privacy policy
                                    </span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
