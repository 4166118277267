
import { api } from '../../api'


export const appApi = api.injectEndpoints({
    endpoints: build => ({
        getAppInfo: build.query<AppInfoResponse, {}>({
            query: () => '/public/app',
        }),
    }),
    overrideExisting: false,
});

export const { useLazyGetAppInfoQuery } = appApi;
