'use client'
import { ThemeProvider } from "next-themes";
import Header from "./Header/Header";
import UserContext from "../contexts/UsertContext";
import Footer from "./Footer";
import { useRef } from "react";
import { Provider } from 'react-redux';
// import { PersistGate } from 'redux-persist/lib/integration/react';
import { store } from '../store';
import { toast, ToastContainer } from 'react-toastify'
import { AppStateProvider } from "@/contexts/AppContext";
import { useRouter, usePathname } from "next/navigation";

export default function Layout({
    children,
}: {
    children: React.ReactNode
}) {
    const scrollRef = useRef({
        scrollPos: 0,
    });
    // header start
    let header;
    const currentPath = usePathname()
    console.log(currentPath)
    return (
        <>
            <ThemeProvider enableSystem={true} attribute="class" defaultTheme="dark">
                <UserContext.Provider value={{ scrollRef: scrollRef }}>
                    <Provider store={store}>
                        <AppStateProvider>
                            <ToastContainer autoClose={5000} />
                            {
                                currentPath == '/manage/chat' ? <>
                                    <Header />
                                    <main>{children}</main>
                                    {/* <Footer /> */}
                                </> : <>
                                    <Header />
                                    <main>{children}</main>
                                    <Footer />
                                </>
                            }

                        </AppStateProvider>
                    </Provider>
                </UserContext.Provider>
            </ThemeProvider>

        </>
    );
}

