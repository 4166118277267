import { createSlice } from '@reduxjs/toolkit';


const slice = createSlice({
    name: 'auth',
    initialState: { token: '', profile: undefined, shop: undefined, products: [], isLoading: true, pendingPayment: undefined, kyc: undefined, firebaseUser: undefined, signal: undefined } as AuthState,
    reducers: {
        setToken(state, action) {
            state.token = action.payload;
        },
        setAuth(state, action) {
            // console.log('Setting logs ', action)
            if (action.payload?.token) state.token = action.payload.token;
            state.profile = action.payload?.profile ?? state.profile;
            state.shop = action.payload?.shop ?? state.shop;
            state.products = action.payload?.products ?? state.products;
            state.pendingPayment = action.payload?.pendingPayment ?? state.pendingPayment
            state.signal = action.payload.signal
            state.isLoading = false
        },
        clearAuth(state, action) {
            console.log('Clearing auth ....')
            state.token = ''
            state.profile = undefined
            state.shop = undefined
            state.products = []
            state.isLoading = false
            state.pendingPayment = undefined
            state.firebaseUser = undefined
            state.signal = undefined
        },
        setProducts(state, action) {
            console.log('Setting products ', action.payload)
            state.products = action.payload
        },
        setProfileLoading(state, action) {
            state.isLoading = action.payload.isLoading
        },
        setKyc(state, action) {
            state.kyc = action.payload.kyc
        },
        setPendingPayment(state, action) {
            state.pendingPayment = action.payload.pendingPayment
        },
        setFirebaseUser(state, action) {
            state.firebaseUser = action.payload
        },
        setSignal(state, action) {
            state.signal = action.payload
        }
    },
});

export type AuthState = {
    isLoading: boolean,
    token?: string;
    profile?: User;
    shop?: Shop;
    products?: Product[];
    pendingPayment?: PendingPayment,
    kyc?: Kyc,
    firebaseUser?: ChatUser,
    signal?: Signal
}

export const { setToken, setAuth, setProfileLoading, clearAuth, setProducts, setKyc, setPendingPayment, setFirebaseUser, setSignal } = slice.actions;
export default slice.reducer;
