export const websiteName = 'DigitalMallEx'
export const aboutUs = {
    tagline: ` A decentralized platform facilitating global access
    to discounted goods and services.`,
    p1: `At Our DigitalmallEx Platform, we provide a decentralized shopping experience that
    empowers entrepreneurs and business Owners by offering them an online space to showcase
    their goods and services. Through our platform, these businesses can effectively increase their
    digital exposures and reach a broader customer base.`,
    p2: `Our unique advantage lies in the opportunity for shop owners within our
    platform to avail themselves of substantial discounts in real estate
    markets, hotel bookings, and various other services. We believe in
    fostering a mutually beneficial environment where businesses can thrive
    while offering attractive incentives to our Users.`,
    p3: `By leveraging our decentralized platform, entrepreneurs can access a
    global marketplace, tapping into new customer segments and expanding
    their businesses' reach. Additionally, our platform's discount offerings in
    partnership with real estate markets, hotels, and other services create a
    competitive edge, enabling businesses to save costs and maximize
    profitability.
    `,
    missionStatement: `Our mission is to foster global
    connectivity by facilitating access
    to premier goods and services at
    discounted rates.`,
    vision: `Our vision is to establish a robust
    decentralized platform for a
    diverse array of goods and
    services while building successful
    entrepreneurs through our
    team-building system and
    leadership culture.
    `,
    values: [
        {
            title: 'Integrity',
            description: `Our bedrock value revolves around transparency, leadership,
            and connectivity, forming the foundation of our operations.
            `,
            icon: 'trust'
        },
        {
            title: 'Connection',
            description: `We are deeply committed to fostering global connectivity,
            linking individuals to goods and services while championing integrity and
            transparency. Our leadership culture is dedicated to enhancing
            entrepreneurs' success through these connections.`,
            icon: 'people'
        },
        {
            title: 'Leadership',
            description: `Our dedication lies in offering top-tier services, facilitating
            training, education, and knowledge dissemination to cultivate robust
            team-building and leadership development.`,
            icon: 'leadership'
        },
        {
            title: 'Innovation',
            description: `Our team remains steadfast in advancing towards global expansion by
            harnessing leadership development, quality services, and innovations that
            drive our business into global markets.`,
            icon: 'innovation'
        },
        {
            title: 'Equity',
            description: `Through our system and leadership, we establish an equal opportunity
            environment for all participants.
            `,
            icon: 'equity'
        },
        {
            title: 'Team Building',
            description: `We strongly advocate for team-building, leadership training, and education,
            equipping potential partners with the necessary knowledge to
            collaboratively build sustainable businesses with us
            `,
            icon: 'team'
        },
    ]
}

export const services = [
    {
        name: 'Shop Space Rents (SSR)',
        description: `The primary service on our platform facilitates
        business owners in showcasing their products
        or services by securing a shop space,
        enabling global connections for
        their offerings.`,
        image: 'shoprent.png'
    },
    {
        name: 'S2S Discount Service',
        description: `This shop-to-shop discount service,
        available on the DigitalmalIEx
        Platform, empowers shop owners to
        enjoy discounts when purchasing from
        each other.`,
        image: 'discount.png'
    },
    {
        name: 'DME Chat',
        description: `Our proprietary chat app, DME Chat, fuels the
        S2S functionality, connecting shop owners for
        business discussions, innovation, training,
        and idea exchange, contributing
        positively to business growth.`,
        image: 'dmechat.png'
    },
    {
        name: 'Real Estate',
        description: `Through partnerships with
        reputable real estate firms
        worldwide, shop owners on our
        platform can secure substantial discounts
        and convenient payment options for landed
        properties in their preferred locations.
        `,
        image: 'realestate.png'
    },
    {
        name: 'Hotels Booking',
        description: `Collaborations with hotel reservation services offer
        our shop owners discounted rates at partnering
        hotels`,
        image: 'hotelbooking2.png'
    },
    {
        name: 'Travel & Tours',
        description: `Shop owners enjoy discounted services
        from partnering travel and tour
        companies, enhancing their
        travel experiences.`,
        image: 'travel.png'
    },
    {
        name: 'Car Hire',
        description: `Our partnering reputable car hire
        companies offer our shop owners
        discounted services, elevating their
        transportation experience and ensuring
        enhanced comfort throughout their journeys.
        `,
        image: 'car-hire.png'
    },
    {
        name: 'Crypto Updates, Training & Education',
        description: `Accessible to every shop owner via our DME Chat
        Platform, this service provides regular updates,
        training, and education on
        cryptocurrency-related topics.
        `,
        image: 'cryptoupdate2.png'
    },
    {
        name: 'Crypto Exchange',
        description: `Our P2P platform provide shop owners access
        to cryptocurrency exchange services,
        enhancing their financial capabilities
        within our platform.
        `,
        image: 'cryptoexchange2.png'
    },
]

export const ssrIncome = [
    { sn: 1, amount: 3.5, percent: 35 },
    { sn: 2, amount: 1.30, percent: 20 },
    { sn: 3, amount: 0.364, percent: 7 },
    { sn: 4, amount: 0.193, percent: 4 },
    { sn: 5, amount: 0.139, percent: 3 },
    { sn: 6, amount: 0.09, percent: 2 },
    { sn: 7, amount: 0.044, percent: 1 },
    { sn: 8, amount: 0.021, percent: 0.5 },
    { sn: 9, amount: 0.01, percent: 0.25 },
]

export const mscIncome = [
    { sn: 1, amount: 0.50, percent: 25 },
    { sn: 2, amount: 0.15, percent: 10 },
    { sn: 3, amount: 0.081, percent: 6 },
    { sn: 4, amount: 0.05, percent: 4 },
    { sn: 5, amount: 0.0365, percent: 3 },
    { sn: 6, amount: 0.0236, percent: 2 },
    { sn: 7, amount: 0.01158, percent: 1 },
    { sn: 8, amount: 0.0114, percent: 1 },
    { sn: 9, amount: 0.0113, percent: 1 },
]

export const faqsData = [
    {
        title: 'How do I add the Polygon Mumbai network to my TrustWallet',
        content: `On your TrustWallet App, click the home icon. Then scroll down and click manage crypto.
        Click the + button at the top right, select the Network Tab, click on the Chainlist and then search for mumbai.
        You should see a network called POLYGONMUMBAI, select it. Click import to add it to your wallet.
        After browsing the website, click the option by the top and change the newtwork to the newly added Polygon Mumbai Testnet.
        Make sure to reload the page after.`
    },
    {
        title: "I changed my network to mumbai, and I am still getting network error",
        content: `It happens sometimes with trustwallet, simply close trustwallet and reopen it`
    }
]